import { useMediaQuery } from '@kaliber/use-media-query'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import mediaStyles from '/cssGlobal/media.css'
import styles from './ProcedureSlider.css'

export function ProcedureSlider({ steps, id = undefined, layoutClassName = undefined }) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm) ?? false
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const initialSlide = 0
  const slidesPerView = isViewportLg
    ? 2.8
    : isViewportMd
      ? 1.8
      : isViewportSm
        ? 1.4
        : 1.2

  const { sliderRef } = useKeenSliderRefs({
    handleSlideChange: () => {},
    slidesNum: steps.length,
    slidesPerView,
    spacing: 16,
    initialSlide,
    sliderDisabled: false,
    origin: 0,
    loop: false,
    interactionTitle: 'procedure-slider'
  })

  return (
    <div className={cx(styles.component, layoutClassName)} {...{ id }}>
      <ContainerLg>
        <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
          {steps.map((step, index) => (
            <div key={index} className={cx(styles.slide, 'keen-slider__slide')} data-style-context={(index === 0) ? 'light-alt' : 'blue'}>
              <Card
                stepTitle={step.stepTitle}
                title={step.title}
                text={step.text}
                layoutClassName={styles.cardLayout}
              />
            </div>
          ))}
        </div>
      </ContainerLg>
    </div>
  )
}

function Card({ title, text, stepTitle = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      {stepTitle && <strong className={styles.stepTitle}>{stepTitle}</strong> }

      <strong className={styles.title}>{title}</strong>

      <p className={styles.text}>{text}</p>
    </div>
  )
}
